import { useNavigate } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Logo from '../../imagens/logo.png';

export const ClienteDashboard = () => {

    const navigate = useNavigate();

    const btnVoltar = () =>{
          navigate('./ClienteAuth.tsx');
    }

     return(

           <div>
               <center>
                  <img src={Logo} alt="Logo do Portal" className={'col-sm-12 col-md-6 col-lg-2'} />
               </center>
               <center className={'col-12 shadow mt-5 p-2 text-body-secondary'}>
                    <h3>Espaço do Parceiro/Cliente</h3>
                    <Button onClick={btnVoltar} variant="danger">Sair</Button>
               </center>

               <center className={'col-12 shadow mt-2 p-3 text-body-secondary'}>
                  <div className="row">
                        <fieldset className="col-lg-12 p-2" style={{border:'solid 1px',backgroundColor:'#C0C0C0', fontWeight:'bold'}}>
                        <span>Flávio José da Costa Souza</span>
                        </fieldset>
                  </div>
                  
               </center>

              
               <center>
                           
                  <Button variant="secondary mt-2">Minhas Compras!</Button>
                  &nbsp;
                  <Button variant="dark mt-2">Minhas O.S!</Button>
                                 
               </center>

               <center>
                  <table className="col-sm-12 col-md-12 col-lg-10 mt-3" >
                        <thead style={{backgroundColor:'#6495ed',color:'blue',fontWeight:'bold', textAlign:'center'}}>
                              <tr>
                                    <td>Situacao</td>
                                    <td>Emissão</td>
                                    <td>Entrega</td>
                                    <td>Valor</td>
                                    <td>Ações</td>
                              </tr>
                        </thead>
                        <tbody>
                              <tr>
                                    <td style={{color:'green',textAlign:'center'}}>Entregue</td>
                                    <td style={{color:'green',textAlign:'center'}}>12/03/2024</td>
                                    <td style={{color:'green',textAlign:'center'}}>12/03/2024</td>
                                    <td style={{color:'green',textAlign:'right'}}>500,00</td>
                                    <td style={{textAlign:'center'}}><Button variant="secondary btn-sm">Ver</Button></td>
                              </tr>

                        </tbody>
                  </table>   
               </center>    
                            
           </div>

     );


}