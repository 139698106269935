import { NavLink } from "react-router-dom";

import Imagens from '../../imagens/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Home = () => {

    return (
       <div id="head-portal">
        
        <br></br>
         <div className="col-lg-12">
            <center>

                <img src={Imagens} alt="logo" className="col-sm-12 col-md-6 col-lg-3" />

                <h1>Portal Eletro Eletronica Xavier</h1>

                <div className="card shadow col-sm-12 col-md-6 col-lg-7">
                <div className="card-body">
                    <h3 className="card-subtitle mb-2 text-body-secondary">site em Construção</h3>
                    <br />
                    <span style={{fontWeight:'bold'}}>Responsavel Tec:</span>&nbsp;
                    <span style={{fontWeight:'bold'}}> VersusTech (94) 98407-3503 Flávio José</span>&nbsp;

                <br /> 
                
                    <NavLink to="/loja" className="card-link">Loja Virtual</NavLink> |
                    <NavLink to="/login" className={"card-link"}>Administrativo</NavLink> |
                    <NavLink to="/clienteauth" className={"card-link"}>Area do Cliente</NavLink>

                </div>
                </div>

            </center>

          </div>
     
       </div>

    );

    
}