import { useNavigate } from "react-router-dom";
import { useRef, useState  } from "react";
import { InputLogin } from "./Components/InputLogin";

export const Login = ()=> {

   const navigate = useNavigate();
   const inputSenhaRef = useRef<HTMLInputElement>(null); // usado para pegar atributos do Input
   
   const [email, setEmail] = useState('')
   const [senha, setSenha] = useState('')

   const btnVoltar = () => {
        navigate('*')
   }

   const entrar = ()=>{
     alert('Estamos em Desenvolvimento');
   }

   return (
      <div>

          <h1>Area de Login</h1>
          <br />
          <form>

              <InputLogin 

                  label="Email"
                  value={email}
                  type="text"
                  onchange={newValue => setEmail(newValue)}
                  onPressEnter={ ()=> inputSenhaRef.current?.focus() }
               
              />
            
              <InputLogin 

                  label="Senha"
                  value={senha}
                  type="password"
                  onchange={newValue => setSenha(newValue)}
                  ref={inputSenhaRef}              
              />


              
            
              <button 
                  type="button" 
                  onClick={entrar}
               >Entrar
               </button>

          </form>
          <br />
          Estamos Trabalhando...

          <br/>

          <button onClick={btnVoltar}>Voltar</button>
      </div>
   )

}