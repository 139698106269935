import React from "react";

interface IClientAuth_Input {
    label:string;
    value:string;
    type:string;
    htmlFor:string;

    onchange:(newValue:string) => void;
}

export const ClientAuth_Input = React.forwardRef<HTMLInputElement, IClientAuth_Input> ((props,ref) =>{
    return(
        
        <label htmlFor={props.htmlFor}>
            
            <span>{props.label}</span>
            
            <input
              ref={ref}
              type={props.type}
              value={props.value}
              onChange={e => props.onchange(e.target.value)}
              className="form-control col-lg-12"
              style={{border:'solid 2px blue'}}
            />
  
        </label>
    );
})