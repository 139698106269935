import React from "react";

import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import fogao from '../../../imagens/products/F1.jpg';

interface ICompLojaProd {
    lblProd:string;
    value:string;
    vPrecoBruto:string;
    vPrecoLiquido:string;
    vPlano:string;
    estoque:string;
}

export const CompLojaProd = React.forwardRef<HTMLInputElement, ICompLojaProd> ((props,ref) =>{

        return(
            <center>
                
                <Card style={{ width: '19rem', height: '28rem' }} className='shadow mt-5 p-1'>
            
                    <center><Card.Img variant="top centered" src={fogao} style={{width:'60%'}} /></center>   
                    
                    <Card.Body>
                        <Card.Text className={'text-body-secondary'}>{props.lblProd}</Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item 
                            style = {{ 
                                     fontWeight:'revert',
                                     border:'none', 
                                     fontSize:'12px', 
                                     textDecoration:'line-through'
                                    }}
                        >{props.vPrecoBruto}
                        </ListGroup.Item>
                        <ListGroup.Item 
                            style = {{
                                     fontWeight:'bold', 
                                     border:'none', 
                                     color:'#00008b', 
                                     fontSize:'25px'
                                    }}
                        >{props.vPrecoLiquido}
                        </ListGroup.Item>
                        <ListGroup.Item 
                            style = {{
                                     fontWeight:'bold', 
                                     fontSize:'12px'
                                    }}
                        >{props.vPlano}
                        </ListGroup.Item>
                        <ListGroup.Item 
                            style = {{
                                     fontWeight:'bold', 
                                     fontSize:'12px'
                                    }}
                        >{props.estoque}
                        </ListGroup.Item>
                    </ListGroup>
                    <Card.Body>
                        <center>
                            <Button 
                             type='button' 
                             value={props.value} 
                             id="btn-comprar"
                             variant='success'
                            >Comprar
                            </Button>
                        </center>
                        
                    </Card.Body>

                </Card>
            </center>
        );

});