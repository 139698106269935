
import { CompCaroussel }  from '../home/Components/CompCarousel';
import { CompLojaNavBar } from './Components/CompLojaNavBar';
import { Cartases }       from './Components/CompCartases';
import { CompLojaProd }   from './Components/CompLojaProd';




import { useState } from 'react';


export const Loja = () => {
     const [prodID, setprodID ] = useState('1');
     const prodIDs = ['id1', 'id2', 'id3', 'id4'];
     return(

            <div>

                <CompLojaNavBar />    

                <div className="mt-2">
                   <CompCaroussel /> 
                </div>

                <center
                    style={{
                    fontWeight: 'bold', 
                    fontSize: '24px',
                    marginTop: '2%',
                    color: 'maroon'
                    }}>Os melhores produtos com os melhores preços no ATACADO e VAREJO...
                
                </center>
    
                <Cartases />


                <center
                    style={{
                    fontWeight: 'bold', 
                    fontSize: '24px',
                    marginTop: '-5%',
                    color: 'maroon'
                    }}>Ofertas Imperdiveis! Confira...
                
                </center>

                <div className='row'>

                        {prodIDs.map((prodID, index) => (
                            <div key={index} className="col-lg-3">
                            <CompLojaProd
                                estoque='8 Unidades Disponiveis'
                                lblProd='FOGAO BRASLAR SIRIUS 4B BCO'
                                vPrecoBruto='de R$ 680,00'
                                vPrecoLiquido='R$ 560,00'
                                vPlano='ou 5x R$ 112,00'
                                value={prodID}
                            />
                            </div>
                        ))}


                    
                   
                </div>



            </div>

        

     );

}