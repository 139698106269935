import { Routes } from "./routes";


export const App = () => {
  return (
     <Routes />
  );
}


