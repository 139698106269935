import { BrowserRouter, Route, Routes as Switch, Navigate } from "react-router-dom";
import { Home, Login, ClienteAuth, ClienteDashboard, Loja } from "../pages";


export const Routes = () =>{
    return (
      <BrowserRouter>
         <Switch>
            <Route path="/home" element={<Home/>} />
            <Route path="*" element={<Loja/>} />

            <Route path="/login" element={<Login/>} />
            <Route path="/clienteauth" element={<ClienteAuth/>} />
            <Route path="/clientedashboard" element={<ClienteDashboard/>} />
            <Route path="/loja" element={<Loja/>} />

         </Switch>
      </BrowserRouter>
    );
}