import React from 'react';

interface IInputLoginProps{
   label: string;
   value:string;
   type:string;

   onchange:(newValue:string) => void;
   onPressEnter?:() => void;

}

export const InputLogin = React.forwardRef<HTMLInputElement, IInputLoginProps> ((props,ref) =>{
    return(
            
            <label htmlFor="">
                 <span>{props.label}</span>
                 <input 
                    ref={ref}
                    type={props.type} 
                    value={props.value} 
                    onChange={e=> props.onchange(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' 
                        ? props.onPressEnter && props.onPressEnter() 
                        : undefined
                    } // escuta o evento do teclado
                  />
            </label>
    );

})
