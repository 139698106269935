import CA1 from '../../../imagens/cartases/ca1.png'
import CA2 from '../../../imagens/cartases/ca2.png'
import CA3 from '../../../imagens/cartases/ca3.png'


export const Cartases = () =>{

    return (
       <div className='row' style={{marginTop:'-4%'}}>
            <div className="col-lg-4">
                <center>
                    <img
                        style={{width:'100%'}}
                        src={CA1}
                        alt="Cartaz"
                    />
                </center>
            </div>
            <div className="col-lg-4">
                <center>
                    <img
                        style={{width:'100%'}}
                        src={CA2}
                        alt="Cartaz"
                    />
                </center>
            </div>
            <div className="col-lg-4">
                <center>
                    <img
                        style={{width:'100%'}}
                        src={CA3}
                        alt="Cartaz"
                    />
                </center>
            </div>
      </div>
 

    );
};