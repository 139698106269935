import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Logo from '../../../imagens/logo.png';
import { NavLink } from "react-router-dom";


export const CompLojaNavBar = () => {


    return (
  
    <div>

        <div className="row">

            <div className="col-lg-3">
               <center>
                    <NavLink to="/loja" className={"card-link"}>
                       <img src={Logo} style={{width:'60%'}} />
                    </NavLink>
                </center>   
            </div>
            <div className="col-lg-5">

                <form>
                    <div className="row">
                       <div className="col-lg-11"><br></br>


                        <InputGroup className="mt-2" >
                            <Form.Control
                            placeholder="Qual Produto vocês está Procurando?"
                            aria-label="Qual Produto vocês está Procurando?"
                            aria-describedby="basic-addon2"
                            style={{
                                color:'#E64752',
                                borderColor: '#E64752'
                            }}
                            />
                            <Button variant="outline-secondary" id="button-addon2" 
                            style={{
                                color:'#E64752',
                                borderColor: '#E64752'
                            }}
                            >
                            <i className="fa-solid fa-magnifying-glass"></i>
                            </Button>
                        </InputGroup>



                   
                        </div>    
                    </div>
                  
                </form>
            </div>
            <div className="col-lg-4" style={{fontSize:'14px'}}>
                <center>
                        <div style={{
                            marginTop:'5%', 
                            display:'inline-block', 
                            color:'#E64752',
                        }}>
                            <NavLink to="/clienteauth" className={"card-link"}>
                                <i className="fa-solid fa-2x fa-user-lock"></i>&nbsp;
                                <span>Area do <br />Parceiro/Cliente</span>
                            </NavLink>

                            
                        </div>
                        &nbsp;
                        <div style={{
                            marginTop:'5%', 
                            display:'inline-block', 
                            marginLeft:'3%', 
                            color:'#E64752',
                        }}>
                            <a href='https://versustech.com.br'>
                            <i className="fa-solid fa-2x fa-user-tie"></i>&nbsp;
                            <span>Area<br />Administrativo</span>
                            </a>
                        </div>
                    
                        <div style={{
                            marginTop:'5%', 
                            display:'inline-block', 
                            marginLeft:'5%', 
                            color:'#E64752',
                        }}>
                            <i className="fa-solid fa-2x fa-cart-shopping"></i>&nbsp;
                            <span>Meu<br/>Carrinho</span>
                        </div>
                </center>
            </div>

        </div>

        <Navbar expand="lg" className={'shadow'} style={{
            backgroundImage:'linear-gradient(to right, #E6070F, #E63B19, #E65F19',
            borderRadius:'20px'
        }}>
        <Container>
            <Navbar.Brand
                style={{
                    color:'white',
                    fontWeight:'bold'
                }}
                 href="#home"
            >Departamentos
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <NavDropdown title="Audio&Video" id="basic-nav-dropdown" 
                style={{
                    color:'white',
                    fontWeight:'bold'
                }}>
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav className="me-auto">
                <NavDropdown title="Colchões" id="basic-nav-dropdown" 
                 style={{
                    color:'white',
                    fontWeight:'bold'
                }}
                >
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav className="me-auto">
                <NavDropdown title="Eletrodomesticos" id="basic-nav-dropdown"
                 style={{
                    color:'white',
                    fontWeight:'bold'
                }}
                >
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav className="me-auto">
                <NavDropdown title="Moveis" id="basic-nav-dropdown"
                 style={{
                    color:'white',
                    fontWeight:'bold'
                }}
                >
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav className="me-auto">
                <NavDropdown title="Informática" id="basic-nav-dropdown"
                                 style={{
                                    color:'white',
                                    fontWeight:'bold'
                                }}
                
                >
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            
            </Navbar.Collapse>
        </Container>
        </Navbar>

    </div>

   
  );
}

