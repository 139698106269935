import { useNavigate } from "react-router-dom";
import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Logo from '../../imagens/logo.png';
import { ClientAuth_Input } from './components/ClientAuth_Input';


export const ClienteAuth = () => {

    const navigate = useNavigate();
    const [cpf_cnpj, setCPF_CNPJ ] = useState('');
    const [pws, setPWS ] = useState('');

    const btnVoltar = () =>{
        navigate('*')
    }

    const entrar = () => {
        navigate('/clientedashboard');
    } 

     return (
  
          <div>
               <center className={'col-12 shadow mt-5 p-2 text-body-secondary'}>
                    <h3>Atendimento ao Cliente</h3>
                    <button onClick={btnVoltar}>Voltar</button>
               </center>

               <div className="row mt-2">
                   
                    <div className='col-lg-7 p-3'>
                        <center>
                            <img 
                               src={Logo} 
                               alt="Logo do Portal"
                               className='col-sm-12 col-md-12 col-lg-9'
                            />
                            <br />
                           
                        </center>
                    </div>

                    <div className='col-lg-5'>

                        <center>
                            <div className="row">
                                <div className="col-6">
                                   <center>
                                       <Button variant="primary mt-3">Meu Primeiro Acesso!</Button>
                                    </center> 
                                  
                                </div>
                                <div className="col-6">
                                    <center>
                                    <Button variant="danger mt-3 ml-3">Esqueci minha Senha!</Button>
                                    </center>
                                  
                                </div>
                            </div>
                            
                            
                        </center><br/>
                        <center className={'mt-3'}>
                            <div className="shadow p-3">
                                <br />
                                 <span className={'text-body-secondary'}>Informe Seus Dados de Acesso!</span>

                                 <div className={'mt-3'}>

                                    <ClientAuth_Input 
                                        htmlFor='cpf_cnpj'
                                        label='CPF ou CNPJ'
                                        type='text'
                                        value={cpf_cnpj}
                                        onchange={newValue => setCPF_CNPJ(newValue)}
                                    />
                                    <br />
                                    <br />
                                    <ClientAuth_Input 
                                        htmlFor='pws'
                                        label='Senha'
                                        type='password'
                                        value={pws}
                                        onchange={newValue => setPWS(newValue)}
                                    />

                                    <br /> 
                                    <Button variant="success mt-3 ml-3" onClick={entrar}>Clique aqui e Entre...</Button>

                   
                                 </div>

                            </div>
                        </center>
                      
                        
                    </div>
               </div>

          </div>

     );

}